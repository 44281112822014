import React from "react"
import { Link } from "gatsby"
import YouTube from "react-youtube"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/games.scss"

// Images
import broken_lines__keyart from "../images/data/broken-lines/hero/keyart.jpg"
import broken_lines__keyart_thumbnail from "../images/data/broken-lines/hero/keyart-thumbnail.jpg"
import broken_lines__gameplay_thumbnail from "../images/data/broken-lines/hero/gameplay-thumbnail.jpg"
import broken_lines__game_about from "../images/data/broken-lines/meta/icon.jpg"
import broken_lines__game_logo from "../images/data/broken-lines/meta/logo.png"

import retro_machina__keyart from "../images/data/retro-machina/hero/keyart.jpg"
import retro_machina__keyart_thumbnail from "../images/data/retro-machina/hero/keyart-thumbnail.jpg"
import retro_machina__gameplay_thumbnail from "../images/data/retro-machina/hero/gameplay-thumbnail.jpg"
import retro_machina__game_about from "../images/data/retro-machina/meta/icon.jpg"
import retro_machina__game_logo from "../images/data/retro-machina/meta/logo.png"

import alchemist_adventure__keyart from "../images/data/alchemist-adventure/hero/keyart.jpg"
import alchemist_adventure__keyart_thumbnail from "../images/data/alchemist-adventure/hero/keyart-thumbnail.jpg"
import alchemist_adventure__gameplay_thumbnail from "../images/data/alchemist-adventure/hero/gameplay-thumbnail.jpg"
import alchemist_adventure__game_about from "../images/data/alchemist-adventure/meta/icon.jpg"
import alchemist_adventure__game_logo from "../images/data/alchemist-adventure/meta/logo.png"

import wonhon__keyart from "../images/data/wonhon/hero/keyart.jpg"
import wonhon__keyart_thumbnail from "../images/data/wonhon/hero/keyart-thumbnail.jpg"
import wonhon__gameplay_thumbnail from "../images/data/wonhon/hero/gameplay-thumbnail.jpg"
import wonhon__game_about from "../images/data/wonhon/meta/icon.jpg"
import wonhon__game_logo from "../images/data/wonhon/meta/logo.png"

import tiltpack__keyart from "../images/data/tiltpack/hero/keyart.jpg"
import tiltpack__keyart_thumbnail from "../images/data/tiltpack/hero/keyart-thumbnail.jpg"
import tiltpack__gameplay_thumbnail from "../images/data/tiltpack/hero/gameplay-thumbnail.jpg"
import tiltpack__game_about from "../images/data/tiltpack/meta/icon.jpg"
import tiltpack__game_logo from "../images/data/tiltpack/meta/logo.png"

import deflector__keyart_thumbnail from "../images/data/deflector/hero/keyart-thumbnail.jpg"
import deflector__gameplay_thumbnail from "../images/data/deflector/hero/gameplay-thumbnail.jpg"
import deflector__game_about from "../images/data/deflector/meta/icon.png"
import deflector__game_logo from "../images/data/deflector/meta/logo.png"

import raji__keyart from "../images/data/raji/hero/keyart.jpg"
import raji__keyart_thumbnail from "../images/data/raji/hero/keyart-thumbnail.jpg"
import raji__gameplay_thumbnail from "../images/data/raji/hero/gameplay-thumbnail.jpg"
import raji__game_about from "../images/data/raji/meta/icon.jpg"
import raji__game_logo from "../images/data/raji/meta/logo.png"

import zelter__keyart from "../images/data/zelter/hero/keyart.jpg"
import zelter__keyart_thumbnail from "../images/data/zelter/hero/keyart-thumbnail.jpg"
import zelter__gameplay_thumbnail from "../images/data/zelter/hero/gameplay-thumbnail.jpg"
import zelter__game_about from "../images/data/zelter/meta/icon.jpg"
import zelter__game_logo from "../images/data/zelter/meta/logo.svg"

import metaphora__keyart from "../images/data/metaphora/hero/keyart.jpg"
import metaphora__keyart_thumbnail from "../images/data/metaphora/hero/keyart-thumbnail.jpg"
import metaphora__game_about from "../images/data/metaphora/meta/icon.jpg"
import metaphora__game_logo from "../images/data/metaphora/meta/logo.png"

import wardens__keyart from "../images/data/wardens/hero/keyart.jpg"
import wardens__keyart_thumbnail from "../images/data/wardens/hero/keyart-thumbnail.jpg"
import wardens__gameplay_thumbnail from "../images/data/wardens/hero/gameplay-thumbnail.jpg"
import wardens__game_about from "../images/data/wardens/meta/icon.jpg"
import wardens__game_logo from "../images/data/wardens/meta/logo.png"

import deadlink__keyart from "../images/data/deadlink/hero/keyart.jpg"
import deadlink__keyart_thumbnail from "../images/data/deadlink/hero/keyart-thumbnail.jpg"
import deadlink__gameplay_thumbnail from "../images/data/deadlink/hero/gameplay-thumbnail.jpg"
import deadlink__game_about from "../images/data/deadlink/meta/icon.png"
import deadlink__game_logo from "../images/data/deadlink/meta/logo.png"

// Videos
import games_video from "../videos/games.mp4"
import alchemist_video from "../videos/alchemist.mp4"
import raji_video from "../videos/raji.mp4"
import deflector_video from "../videos/deflector.mp4"
import metaphora_video from "../videos/metaphora.mp4"
import retro_machina_video from "../videos/retro-machina.mp4"
import broken_lines_video from "../videos/broken-lines.mp4"
import zelter_video from "../videos/zelter.mp4"
import wonhon_video from "../videos/wonhon.mp4"
import tilt_pack_video from "../videos/tilt-pack.mp4"
import wardens_video from "../videos/wardens.mp4"
import deadlink_video from "../videos/deadlink.mp4"

class GamesPage extends React.Component {

  render(){

    return(
      <Layout>

        <SEO title="Games"/>
        <Link to="/" className="logo" activeClassName="-active">Super!com</Link>
        <h2 className="title">Games</h2>

        <div className="games">

          <div className="scroll-arrow"></div>
        
          <section className="games__hero">

            <div className="games__hero__video">
              <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                <source src={games_video} type="video/mp4"/>
              </video>
            </div>

            <div className="center">

              <h2 className="games__hero__title">
                Developers <span className="games__hero__title__link">invest a part of themselves</span> into every game they make.
              </h2>
              <p className="games__hero__message">
                Each game is a very personal adventure, through <br/>
                the prism of which talented creators want to share <br/>
                the most intimate and exciting stories.
              </p>
            
            </div>

          </section>

          <div className="center">

          <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/deadlink/" className="games__card__title__link">
                  Deadlink
                </Link>
                <div className="games__card__title__stores">
                  <a href="https://store.steampowered.com/app/1676130/Deadlink/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={deadlink__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={deadlink_video} type="video/mp4"/>
                      </video>
                      <img src={deadlink__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="glok5ozuIIE"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'glok5ozuIIE',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + deadlink__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + deadlink__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={deadlink__game_about} alt="Deadlink"/>
                  </div>
                  <p className="games__card__footer__about__text">
                    Deadlink is a cyberpunk FPS with roguelite elements. You're the first operative to be recruited to the experimental Deadlink project. Pilot an autonomous combat shell, fighting your way through cramped slums, twisted labs, grimy warehouses, and sleek office buildings on a mission to thwart the schemes of the most powerful corporations in the world. Wield a deadly arsenal, upgrade your skills and tech, devastate destructible environments, and slash corporate profit margins — all at the same time.
                  </p>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/1676130/Deadlink/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  </div>
                </div>
                <a href="https://playdeflector.com" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={deadlink__game_logo} className="games__card__footer__logo" alt="Deadlink"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/deadlink/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/raji/" className="games__card__title__link">
                  RAJI: <br/>
                  AN ANCIENT EPIC
                </Link>
                <div className="games__card__title__stores">
                  <a href="https://store.steampowered.com/app/730390/Raji_An_Ancient_Epic/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  <a className="games__card__footer__stores__line__item -switch"
                    data-america-url="https://www.nintendo.com/games/detail/raji-an-ancient-epic-switch/"
                    data-europe-url="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Raji-An-Ancient-Epic-1821392.html?s=countries"
                    target="_blank" rel="noopener noreferrer">switch</a>
                  <a className="games__card__footer__stores__line__item -ps"
                      href="https://store.playstation.com/en-us/product/UP5729-CUSA20607_00-RAJIANANCIENTEPI"
                      data-europe-url="https://store.playstation.com/ru-ru/product/EP5911-CUSA20344_00-RAJIANANCIENTEPI"
                      target="_blank" rel="noopener noreferrer">ps</a>
                  <a href="https://www.microsoft.com/en-us/p/raji-an-ancient-epi%D1%81/9nh2j7x3mpb0" className="games__card__footer__stores__line__item -xbox" target="_blank" rel="noopener noreferrer">xbox</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={raji__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={raji_video} type="video/mp4"/>
                      </video>
                      <img src={raji__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="X69LkDDOk-w"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'X69LkDDOk-w',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + raji__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + raji__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={raji__game_about} alt="Raji"/>
                  </div>
                  <p className="games__card__footer__about__text">
                    Raji: An Anсient Epic is an action-adventure game set in
                    ancient India. Survive the demonic invasion as Raji, a young
                    and agile street performer, who uses the blessings of the gods
                    to unlock mysterious powers and rescue her little brother
                    who has been kidnapped by the forces of evil.
                  </p>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/730390/Raji_An_Ancient_Epic/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                    <a className="games__card__footer__stores__line__item -switch"
                      data-america-url="https://www.nintendo.com/games/detail/raji-an-ancient-epic-switch/"
                      data-europe-url="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Raji-An-Ancient-Epic-1821392.html?s=countries"
                      target="_blank" rel="noopener noreferrer">switch</a>
                  </div>
                  <div className="games__card__footer__stores__line">
                    <a className="games__card__footer__stores__line__item -ps"
                        href="https://store.playstation.com/en-us/product/UP5729-CUSA20607_00-RAJIANANCIENTEPI"
                        data-europe-url="https://store.playstation.com/ru-ru/product/EP5911-CUSA20344_00-RAJIANANCIENTEPI"
                        target="_blank" rel="noopener noreferrer">ps</a>
                    <a href="https://www.microsoft.com/en-us/p/raji-an-ancient-epi%D1%81/9nh2j7x3mpb0" className="games__card__footer__stores__line__item -xbox" target="_blank" rel="noopener noreferrer">xbox</a>
                  </div>
                </div>
                <a href="https://www.rajithegame.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={raji__game_logo} className="games__card__footer__logo" alt="Raji"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/raji/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            <section className="games__card">

              <h3 className="games__card__title">
                <Link to="/games/deflector/" className="games__card__title__link">
                  Deflector
                </Link>
                <div className="games__card__title__stores">
                  <a href="https://store.steampowered.com/app/1736550/Deflector/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={raji__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={deflector_video} type="video/mp4"/>
                      </video>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="OFUvFIVgv-8"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'OFUvFIVgv-8',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + deflector__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + deflector__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={deflector__game_about} alt="Deflector"/>
                  </div>
                  <div className="games__card__footer__about__text">
                    Deflector is a high-octane bullet hell roguelite set in a deadly world. Upgrade your characters with the DNA you find, evolve them further during your runs and explore the farthest reaches of this microcosm… or die trying.
                  </div>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/1736550/Deflector/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  </div>
                </div>
                <a href="https://playdeflector.com" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={deflector__game_logo} className="games__card__footer__logo" alt="Deflector"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/deflector/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>

            </section>

            <section className="games__card">
            
              <h3 className="games__card__title">
                <Link to="/games/zelter/" className="games__card__title__link">
                  Zelter
                </Link>
                <div className="games__card__title__stores">
                  <a href="https://store.steampowered.com/app/1193340/Zelter/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={zelter__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={zelter_video} type="video/mp4"/>
                      </video>
                      <img src={zelter__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="aIvb71cfWtA"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'aIvb71cfWtA',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + zelter__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + zelter__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={zelter__game_about} alt="Zelter"/>
                  </div>
                  <div className="games__card__footer__about__text">
                    The zombie apocalypse is a tough situation to overcome, even
                    if it takes place in a cute pixelated universe! Try to defend your
                    home and save people from the hordes of zombies in this new
                    crafting survival game.
                  </div>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/1193340/Zelter/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  </div>
                </div>
                <a href="https://playzelter.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={zelter__game_logo} className="games__card__footer__logo" alt="Zelter"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/zelter/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/alchemist-adventure/" className="games__card__title__link">
                  Alchemist <br/>
                  Adventure
                </Link>
                <div className="games__card__title__stores">
                  <a href="https://store.steampowered.com/app/521620/Alchemist_Adventure/?utm_source=supercom&utm_medium=gamesk&utm_campaign=storeicon" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  <a href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Alchemist-Adventure-1971435.html?s=countries" className="games__card__footer__stores__line__item -switch" target="_blank" rel="noopener noreferrer">switch</a>
                  <a href="https://store.playstation.com/en-us/product/UP5729-CUSA20608_00-CHEMISTADVENTURE" className="games__card__footer__stores__line__item -ps" target="_blank" rel="noopener noreferrer">ps</a>
                  <a href="https://www.microsoft.com/en-us/p/alchemist-adventure/9pfv931bvlps?activetab=pivot:overviewtab" className="games__card__footer__stores__line__item -xbox" target="_blank" rel="noopener noreferrer">xbox</a>
                  <a href="https://www.gog.com/game/alchemist_adventure" className="games__card__footer__stores__line__item -gog" target="_blank" rel="noopener noreferrer">gog</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={alchemist_adventure__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={alchemist_video} type="video/mp4"/>
                      </video>
                      <img src={alchemist_adventure__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="cRhg-hYDD9k"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'cRhg-hYDD9k',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + alchemist_adventure__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + alchemist_adventure__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={alchemist_adventure__game_about} alt="Alchemist Adventure"/>
                  </div>
                  <p className="games__card__footer__about__text">
                    An action-adventure game set in fantasy world. Follow the story of Mya, a young alchemist trying to solve the mystery of a forgotten land while searching for her loved ones. Solve puzzles and fight monsters using the power of alchemy. Combine fire, water, air, earth, and a whole host of different ingredients in your concoctions.
                  </p>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/521620/Alchemist_Adventure/?utm_source=supercom&utm_medium=gamesk&utm_campaign=storeicon" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                    <a href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Alchemist-Adventure-1971435.html?s=countries" className="games__card__footer__stores__line__item -switch" target="_blank" rel="noopener noreferrer">switch</a>
                    <a href="https://store.playstation.com/en-us/product/UP5729-CUSA20608_00-CHEMISTADVENTURE" className="games__card__footer__stores__line__item -ps" target="_blank" rel="noopener noreferrer">ps</a>
                  </div>
                  <div className="games__card__footer__stores__line">
                    <a href="https://www.microsoft.com/en-us/p/alchemist-adventure/9pfv931bvlps?activetab=pivot:overviewtab" className="games__card__footer__stores__line__item -xbox" target="_blank" rel="noopener noreferrer">xbox</a>
                    <a href="https://www.gog.com/game/alchemist_adventure" className="games__card__footer__stores__line__item -gog" target="_blank" rel="noopener noreferrer">gog</a>
                  </div>
                </div>
                <a href="https://alchemistadventure.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={alchemist_adventure__game_logo} className="games__card__footer__logo" alt="Alchemist Adventure"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/alchemist-adventure/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/metaphora/" className="games__card__title__link">
                  METAPHORA <br/>
                  THE MOONYCAT QUEST
                  <span className="games__card__title__link__released">
                    <span>Coming Soon</span>
                  </span>
                </Link>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible -alone">
                    <img src={metaphora__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={metaphora_video} type="video/mp4"/>
                      </video>
                      <img src={metaphora__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    {/* <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="k_ibr8Fhz30"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'k_ibr8Fhz30',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div> */}
                  </div>
                  <button className="games__card__video__button -key-art -active -alone" data-id="keyart" style={{backgroundImage: 'url(' +  metaphora__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  {/* <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + metaphora__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button> */}
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={metaphora__game_about} alt="Metaphora"/>
                  </div>
                  <div className="games__card__footer__about__text">
                    An animated action-adventure with an engaging story about regenerating the world by switching between characters, exploring, solving puzzles, and fighting contamination. <br/>
                    In Metaphora, you explore the planet and make the world blossom again in a collaborative way by switching among three types of cute characters, each with unique abilities.
                  </div>
                </div>
                <div className="games__card__footer__stores">
                  {/* <div className="games__card__footer__stores__line">
                    <div className="games__card__footer__stores__line__item -steam">steam</div>
                    <div className="games__card__footer__stores__line__item -switch">switch</div>
                  </div>
                  <div className="games__card__footer__stores__line">
                    <div className="games__card__footer__stores__line__item -ps">ps</div>
                    <div className="games__card__footer__stores__line__item -xbox">xbox</div>
                  </div> */}
                </div>
                <img src={metaphora__game_logo} className="games__card__footer__logo" alt="Metaphora"/>
              </footer>

              <div className="games__card__more">
                <Link to="/games/metaphora/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/retro-machina/" className="games__card__title__link">
                  Retro <br/>
                  Machina
                </Link>
                <div className="games__card__title__stores">
                  <a href="https://store.steampowered.com/app/1127970/Retro_Machina/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  <a href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Retro-Machina-1970906.html?s=countries" className="games__card__footer__stores__line__item -switch" target="_blank" rel="noopener noreferrer">switch</a>
                  <a href="https://store.playstation.com/en-us/product/UP5729-CUSA26101_00-RETROMACHINAGAME" className="games__card__footer__stores__line__item -ps" target="_blank" rel="noopener noreferrer">ps</a>
                  <a href="https://www.microsoft.com/en-us/p/retro-machina/9pjqtkgbfd63#activetab=pivot:overviewtab" className="games__card__footer__stores__line__item -xbox" target="_blank" rel="noopener noreferrer">xbox</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={retro_machina__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={retro_machina_video} type="video/mp4"/>
                      </video>
                      <img src={retro_machina__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="k_ibr8Fhz30"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'k_ibr8Fhz30',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + retro_machina__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + retro_machina__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={retro_machina__game_about} alt="Retro Machina"/>
                  </div>
                  <p className="games__card__footer__about__text">
                    Retro Machina is a single-player action game with puzzle elements in a retro-futuristic setting. The game follows the journey of a small robot thrown out of the last perfect city, which is inhabited by machines. Unravel the mysteries of a long forgotten world, the last city, and its inhabitants, both new and ancient; find a way to repair yourself and return home, or use your defect to change the world.
                  </p>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/1127970/Retro_Machina/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                    <a href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Retro-Machina-1970906.html?s=countries" className="games__card__footer__stores__line__item -switch" target="_blank" rel="noopener noreferrer">switch</a>
                  </div>
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.playstation.com/en-us/product/UP5729-CUSA26101_00-RETROMACHINAGAME" className="games__card__footer__stores__line__item -ps" target="_blank" rel="noopener noreferrer">ps</a>
                    <a href="https://www.microsoft.com/en-us/p/retro-machina/9pjqtkgbfd63#activetab=pivot:overviewtab" className="games__card__footer__stores__line__item -xbox" target="_blank" rel="noopener noreferrer">xbox</a>
                  </div>
                </div>
                <a href="https://retromachina.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={retro_machina__game_logo} className="games__card__footer__logo" alt="Retro Machina"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/retro-machina/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/broken-lines/" className="games__card__title__link">
                  Broken <br/>
                  Lines
                </Link>
                <div className="games__card__title__stores">
                  <a href="https://store.steampowered.com/app/926580/Broken_Lines/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  <a href="https://www.gog.com/game/broken_lines" className="games__card__footer__stores__line__item -gog" target="_blank" rel="noopener noreferrer">gog</a>
                  <a className="games__card__footer__stores__line__item -switch"
                    data-america-url="https://www.nintendo.com/games/detail/broken-lines-switch/"
                    data-europe-url="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Broken-Lines-1763504.html"
                    target="_blank" rel="noopener noreferrer">switch</a>
                  <a href="https://store.my.games/play/game/broken_lines/" className="games__card__footer__stores__line__item -mygames" target="_blank" rel="noopener noreferrer">mygames</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={broken_lines__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={broken_lines_video} type="video/mp4"/>
                      </video>
                      <img src={broken_lines__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="g-NyV4eTETk"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'g-NyV4eTETk',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + broken_lines__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + broken_lines__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={broken_lines__game_about} alt="Broken Lines"/>
                  </div>
                  <p className="games__card__footer__about__text">
                    Broken Lines is a story-driven tactical RPG set in an alternate-history version of World War II. It is a game about a group of eight soldiers who have crash-landed behind enemy lines. It is up to you to lead them home while fending off the enemy and helping them deal with the horrors of war.
                  </p>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/926580/Broken_Lines/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                    <a href="https://www.gog.com/game/broken_lines" className="games__card__footer__stores__line__item -gog" target="_blank" rel="noopener noreferrer">gog</a>
                  </div>
                  <div className="games__card__footer__stores__line">
                    <a className="games__card__footer__stores__line__item -switch"
                      data-america-url="https://www.nintendo.com/games/detail/broken-lines-switch/"
                      data-europe-url="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Broken-Lines-1763504.html"
                      target="_blank" rel="noopener noreferrer">switch</a>
                    <a href="https://store.my.games/play/game/broken_lines/" className="games__card__footer__stores__line__item -mygames" target="_blank" rel="noopener noreferrer">mygames</a>
                  </div>
                  <div className="games__card__footer__stores__line">
                    {/* <div className="games__card__footer__stores__line__item -gfn">gfn</div>
                    <div className="games__card__footer__stores__line__item -ps">ps</div>
                      <div className="games__card__footer__stores__line__item -xbox">xbox</div> */}
                  </div>
                </div>
                <a href="https://brokenlinesgame.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={broken_lines__game_logo} className="games__card__footer__logo" alt="Broken Lines"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/broken-lines/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/wonhon/" className="games__card__title__link">
                  WONHON: <br/>
                  A VENGEFUL SPIRIT
                </Link>
                <div className="games__card__title__stores">
                  <a href="https://store.steampowered.com/app/1294340/Wonhon__a_Vengeful_Spirit" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={wonhon__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={wonhon_video} type="video/mp4"/>
                      </video>
                      <img src={wonhon__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="DX3307Prgw0"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'DX3307Prgw0',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + wonhon__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + wonhon__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={wonhon__game_about} alt="wonhon"/>
                  </div>
                  <p className="games__card__footer__about__text">
                    Created by a solo developer, Wonhon is a paranormal stealth-action and tactics game about a young Korean girl killed in a military conflict and returned from the dead with supernatural powers to exact revenge for herself and her people.
                  </p>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/1294340/Wonhon__a_Vengeful_Spirit" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  </div>
                </div>
                <a href="https://wonhon-game.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={wonhon__game_logo} className="games__card__footer__logo" alt="wonhon"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/wonhon/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/tiltpack/" className="games__card__title__link">
                  Tilt <br/>
                  Pack
                </Link>
                <div className="games__card__title__stores">
                  <a className="games__card__footer__stores__line__item -switch"
                    data-america-url="https://www.nintendo.com/games/detail/tilt-pack-switch/"
                    data-europe-url="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Tilt-Pack-1714118.html"
                    target="_blank" rel="noopener noreferrer">switch</a>
                </div>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={tiltpack__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={tilt_pack_video} type="video/mp4"/>
                      </video>
                      <img src={tiltpack__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="n8gSKbuAre4"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'n8gSKbuAre4',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + tiltpack__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + tiltpack__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={tiltpack__game_about} alt="TiltPack"/>
                  </div>
                  <p className="games__card__footer__about__text">
                    Push and stomp your way to victory in this exciting
                    brawler for friends and family! Choose your fighter
                    and dive into action-packed gameplay with various
                    power-ups and interactive arenas.
                  </p>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a className="games__card__footer__stores__line__item -switch"
                      data-america-url="https://www.nintendo.com/games/detail/tilt-pack-switch/"
                      data-europe-url="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Tilt-Pack-1714118.html"
                      target="_blank" rel="noopener noreferrer">switch</a>
                  </div>
                </div>
                <a href="https://tiltpack.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={tiltpack__game_logo} className="games__card__footer__logo" alt="TiltPack"/>
                </a>
              </footer>

              <div className="games__card__more">
                <Link to="/games/tiltpack/" className="games__card__more__link">
                  <span className="games__card__more__link__text">MORE ABOUT THE GAME</span>
                </Link>
              </div>
              
            </section>

            {/* <section className="games__card">
              
              <h3 className="games__card__title">
                <Link to="/games/wardens/" className="games__card__title__link">
                  WARDENS: <br/>
                  THE LOST LEGACY
                </Link>
              </h3>

              <div className="games__card__content">
                <div className="games__card__video">
                  <div className="games__card__video__visible">
                    <img src={wardens__keyart} className="games__card__video__visible__size-setter"  alt=""/>
                    <div className="games__card__video__visible__item" data-id="keyart">
                      <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                        <source src={wardens_video} type="video/mp4"/>
                      </video>
                      <img src={wardens__keyart} className="games__card__video__visible__item__media"  alt=""/>
                    </div>
                    <div className="games__card__video__visible__item" data-id="gameplay">
                      <YouTube
                        videoId="X69LkDDOk-w"
                        opts={{
                          playerVars: {
                            controls: 0,
                            playlist: 'X69LkDDOk-w',
                            loop: 1,
                            showinfo: 0,
                            autoplay: 1,
                            start: 0,
                            mute: 1,
                            enablejsapi: 1
                          }
                        }}
                        // onReady={_onReady}
                      ></YouTube>
                    </div>
                  </div>
                  <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + wardens__keyart_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Key Art</span>
                  </button>
                  <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + wardens__gameplay_thumbnail + ')'}}>
                    <span className="games__card__video__button__name">Gameplay</span>
                  </button>
                </div>
              </div>

              <footer className="games__card__footer">
                <div className="games__card__footer__about">
                  <div className="games__card__footer__about__image">
                    <img src={wardens__game_about} alt="wardens"/>
                  </div>
                  <div className="games__card__footer__about__text">
                    Push and stomp your way to victory in this exciting
                    brawler for friends and family! Choose your fighter
                    and dive into action-packed gameplay with various
                    power-ups and interactive arenas.
                  </div>
                </div>
                <div className="games__card__footer__stores">
                  <div className="games__card__footer__stores__line">
                    <a href="https://store.steampowered.com/app/730390/wardens_An_Ancient_Epic/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                    <a className="games__card__footer__stores__line__item -switch"
                        data-america-url="https://www.nintendo.com/games/detail/wardens-an-ancient-epic-switch/"
                        data-europe-url="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/wardens-An-Ancient-Epic-1821392.html?s=countries"
                        target="_blank" rel="noopener noreferrer">switch</a>
                  </div>
                  <div className="games__card__footer__stores__line">
                    <div className="games__card__footer__stores__line__item -ps">ps</div>
                    <div className="games__card__footer__stores__line__item -xbox">xbox</div>
                  </div>
                </div>
                <a href="https://www.wardensthegame.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                  <img src={wardens__game_logo} className="games__card__footer__logo" alt="wardens"/>
                </a>
              </footer>
              
            </section> */}

          </div>

          <section className="games__promo">

            <div className="games__promo__string">
              <div className="games__promo__string__icon -coin -stroke"></div>
              <div className="games__promo__string__item -white">We optimize</div>
              <div className="games__promo__string__icon -coin -solid"></div>
              <div className="games__promo__string__item -red">We optimize</div>
              <div className="games__promo__string__icon -coin -stroke"></div>
              <div className="games__promo__string__item -white">We <span>optimize</span></div>
            </div>
            <div className="games__promo__string">
              <div className="games__promo__string__icon -speaker -stroke"></div>
              <div className="games__promo__string__item -red">Your performance</div>
              <div className="games__promo__string__icon -speaker -solid"></div>
              <div className="games__promo__string__item -white">Your <span>performance</span></div>
              <div className="games__promo__string__icon -speaker -stroke"></div>
              <div className="games__promo__string__item -red">Your performance</div>
            </div>

            <div className="center">
              <div className="games__promo__image">Focus</div>
              <div className="games__promo__text">On Creating Games</div>
              <div className="games__promo__button">
                <button className="games__promo__button__link" data-role="call-to-action">
                  <span className="games__promo__button__link__text">Give a boost to your game</span>
                </button>
                <div className="games__promo__button__shadow"></div>
              </div>
            </div>

          </section>

        </div>
        
      </Layout>
    );

  }

}

export default GamesPage
